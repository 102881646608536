import { DateTime, Settings, WeekdayNumbers } from 'luxon'

import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

interface DateTimeConfig {
  locale: string
  firstDay: WeekdayNumbers
  minimalDays: WeekdayNumbers
  weekend: WeekdayNumbers[]
  defaultZone: string
}

const dateTime = (date?: string) => {
  return date ? DateTime.fromISO(date) : DateTime.local()
}

const dateTimeUtc = (date?: string) => {
  return date ? DateTime.fromISO(date, { zone: 'utc' }) : DateTime.utc()
}

const stringToTimeStamp = (date: string) => {
  return toTimestamp(dateTime(date))
}

const toTimestamp = (dateTime: DateTime): Timestamp => {
  const ts = new Timestamp()
  ts.setSeconds(dateTime.toSeconds())
  return ts
}

/**
 * Convert seconds to DateTime. Can be used with timestamp from google.protobuf.Timestamp if you convert it to seconds.
 */
const dateTimeFromSeconds = (seconds: number) => {
  return DateTime.fromSeconds(seconds)
}

const formatDateTime = (date: DateTime, format: DateTimeFormats) => {
  return date.toLocaleString(format)
}

const configureDateTime = (config: DateTimeConfig) => {
  Settings.defaultLocale = config.locale ?? 'se'
  Settings.defaultWeekSettings = {
    firstDay: config.firstDay ?? 1,
    minimalDays: config.minimalDays ?? 1,
    weekend: config.weekend ?? [6, 7],
  }
  Settings.defaultZone = config.defaultZone ?? 'Europe/Stockholm'
}

/**
 * Some of the formats but more can be found here https://moment.github.io/luxon/#/formatting
 */
const FORMAT = {
  DATE_SHORT: DateTime.DATE_SHORT,
  DATE_FULL: DateTime.DATE_FULL,
  DATE_HUGE: DateTime.DATE_HUGE,
  TIME_SIMPLE: DateTime.TIME_SIMPLE,
  TIME_WITH_SECONDS: DateTime.TIME_WITH_SECONDS,
  TME_24_SIMPLE: DateTime.TIME_24_SIMPLE,
  TIME_24_WITH_SECONDS: DateTime.TIME_24_WITH_SECONDS,
  DATETIME_SHORT: DateTime.DATETIME_SHORT,
  DATETIME_MED: DateTime.DATETIME_MED,
  DATETIME_FULL: DateTime.DATETIME_FULL,
  DATETIME_SHORT_WITH_SECONDS: DateTime.DATETIME_SHORT_WITH_SECONDS,
} as const

type DateTimeFormats = (typeof FORMAT)[keyof typeof FORMAT]

export {
  dateTime,
  configureDateTime,
  formatDateTime,
  dateTimeFromSeconds,
  toTimestamp,
  stringToTimeStamp,
  dateTimeUtc,
  FORMAT,
}
export type { DateTimeConfig, DateTimeFormats }
